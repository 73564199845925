import axios from "axios";

const Axios = axios.create({
    baseURL: process.env.BASE_API,
    responseType: "json",
    withCredentials: true,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    }
});

Axios.interceptors.request.use(config => {
    if (sessionStorage.getItem("token")) {
        config.headers['token'] = sessionStorage.getItem("token")
    }
    return config;
},
    function (error) {
        return Promise.reject(error)
    }
);

Axios.interceptors.response.use(res => {
    if (res.data.code == '500') {
        sessionStorage.removeItem("token");
        window.location.href = `http://ztshop.jiaminghudong.cn`;
    }
    return res
},
    function (res) {
        return Promise.reject(res)
    }
);
export default Axios