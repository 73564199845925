import request from '@/assets/utils/request'

export function init(data) {
    return request({
        url: 'http://shop.wesky.online/Index/init',
        method: 'POST',
        data
    })
}
export function login(data) {
    return request({
        url: 'http://shop.wesky.online/Login/login',
        method: 'POST',
        data
    })
}
export function register(data) {
    return request({
        url: 'http://shop.wesky.online/Login/register',
        method: 'POST',
        data
    })
}
export function addNewProduct(data) {
    return request({
        url: 'http://shop.wesky.online/pay/addNewProduct',
        method: 'POST',
        data
    })
}
export function addOldProduct(data) {
    return request({
        url: 'http://shop.wesky.online/pay/addOldProduct',
        method: 'POST',
        data
    })
}
export function contractCreate(data) {
    return request({
        url: 'http://shop.wesky.online/Contract/create',
        method: 'POST',
        data
    })
}
export function payGetList(data) {
    return request({
        url: 'http://shop.wesky.online/Pay/getList',
        method: 'POST',
        data
    })
}
export function getMemberInfo(data) {
    return request({
        url: 'http://shop.wesky.online/Member/getMemberInfo',
        method: 'POST',
        data
    })
}
export function createPay(data) {
    return request({
        url: 'http://shop.wesky.online/pay/create_pay',
        method: 'POST',
        data
    })
}
export function uploads(data) {
    return request({
        url: 'http://shop.wesky.online/uploads/file',
        method: 'POST',
        data
    })
}
export function setMember(data) {
    return request({
        url: 'http://shop.wesky.online/Member/setMember',
        method: 'POST',
        data
    })
}
export function hetong(data) {
    return request({
        url: 'http://shop.wesky.online/Contract/hetong',
        method: 'POST',
        data
    })
}
export function preview(data) {
    return request({
        url: 'http://shop.wesky.online/Contract/preview_hetong',
        method: 'POST',
        data
    })
}

export function contractList() {
    return request({
        url: 'http://shop.wesky.online/Contract/getlist',
        method: 'get'
    })
}

export function getNotice() {
    return request({
        url: 'http://shop.wesky.online/login/xieyi',
        method: 'get'
    })
}

export function queryWxOrderstatus(data) {
    return request({
        url: 'http://shop.wesky.online/Pay/queryWxOrderstatus',
        method: 'POST',
        data
    })
}